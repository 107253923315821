<template>
  <base-layout>
    <add-edit-restricted-area />
    <content-section :spacing="false">
      <div class="content-spacing">
        <!-- <div class="grid grid-cols-1 mb-10 md:grid-cols-2"> -->
        <div class="grid grid-cols-1 mb-10">
          <div class="">
            <title-plus title="Restricted Areas" @plus="add" />
          </div>

          <!-- <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div> -->
        </div>

        <div class="flex flex-col mt-4 mb-10 md:items-center md:flex-row">
          <card-tax-stat
            title="Total Restricted Areas"
            :value="indexMetaData.summary.total"
            variant="green"
            class=""
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewRestrictedArea',
                  params: { id: item.id },
                }"
              >
                #{{ item.id | onlyLastFive }}
              </router-link>
            </fsTableRowItem>

            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewRestrictedArea',
                  params: { id: item.id },
                }"
              >
                {{ item.name }}
              </router-link>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.geofence ? item.geofence.name : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>{{ item.address }}</fsTableRowItem>

            <fsTableRowItem>
              <x-status
                :variant="item.is_active ? 'green' : 'gray'"
                :text="item.is_active ? 'Active' : 'Inactive'"
                profile="fleet"
              />
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :key="`more-actions-${index}`"
                  :type="`restrictedArea`"
                  :data="item"
                  @change="handleAction('change', index, $event)"
                  @delete="handleAction('delete', index, $event)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
// import DateRangePicker from "@/components/picker/date-range/DateRangePicker";
import OtoSearch from '@/components/ui/OtoSearch'
import XStatus from '@/components/badge/XStatus'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import { EventBus } from '@/utils/EventBus'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { RestrictedAreaConfig } from '@/config/RestrictedAreaConfig'
import AddEditRestrictedArea from './AddEditRestrictedArea'
import CardTaxStat from '@/components/cards/TaxStat'

export default {
  name: 'RestrictedAreas',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    OtoSearch,
    // DateRangePicker,
    CardTaxStat,
    XStatus,
    ExportAsDropdown,
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),
    AddEditRestrictedArea,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: RestrictedAreaConfig.api.index,
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Name',
          width: '15%',
          field: 'name',
          sort: true,
          order: '',
        },
        {
          name: 'Service Area Name',
          width: '15%',
          field: 'geofence',
          sort: true,
          order: '',
        },
        {
          name: 'Address',
          width: '15%',
          field: 'address',
          sort: null,
          order: '',
        },
        {
          name: 'Status',
          width: '15%',
          field: 'is_active',
          sort: true,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(RestrictedAreaConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    add() {
      EventBus.$emit(RestrictedAreaConfig.events.editingData, {
        // enable status-switch by default
        should_throttle: true,
      })
      dispatchEvent(new Event(RestrictedAreaConfig.events.sorToggle))
    },
    async edit(item) {
      await this.$http
        .get(RestrictedAreaConfig.api.details(item.id))
        .then((res) => {
          console.log('restricted = ', res.data)
          EventBus.$emit(RestrictedAreaConfig.events.editingData, res.data)
          dispatchEvent(new Event(RestrictedAreaConfig.events.sorToggle))
        })
        .catch((err) => console.log(err))
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return RestrictedAreaConfig.api.details(id)
    },
    handleAction(type, index, data) {
      if (type === 'change') {
        this.indexData[index].is_active = data.is_active
        const activeInactive = data.is_active === true ? 'active' : 'inactive'
        const activatedDeactivated =
          data.is_active === true ? 'activated' : 'deactivated'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Restricted area ${activatedDeactivated}`,
          text: `${data.name} restricted area is now ${activeInactive}`,
        })

        return
      }

      if (type === 'delete') {
        const name = this.indexData[index].name || '--'
        this.getIndexData(this.getQueryString)

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Restricted area deleted`,
          text: `${name} restricted area has been deleted`,
        })

        return
      }
    },
  },
}
</script>

<style></style>
