export const ServiceAreaConfig = {
  api: {
    // get
    index: '/dashboard/service-areas/',
    //get
    all: '/dashboard/service-areas/?dropdown',
    // post
    create: '/dashboard/service-areas/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/service-areas/${id}/`
    },
    details: (id = 'uuid') => {
      return `/dashboard/service-areas/${id}/`
    },
    child: (id = 'uuid') => {
      return `/dashboard/service-areas/${id}/child-areas/`
    },
  },
  events: {
    name: 'service-area',
    // refresh-index-data
    refresh: `rid-service-area`,
    // slideover-right
    sorId: 'service-area',
    sorOpen: 'sor-open-service-area',
    sorClose: 'sor-close-service-area',
    sorToggle: 'sor-toggle-service-area',
    sorViewToggle: 'sor-toggle-service-area-view',
    sorCloseToggle: 'sor-Close-service-area-view',
    // editing-data
    editingData: 'edit-service-area-data',
    // viewing-data
    viewingData: 'view-service-area-data',
  },
}
