<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <add-edit-restricted-area />
    <div class="flex flex-wrap" v-if="restricted_area != null">
      <div :class="is_full ? 'w-0' : 'w-1/2 px-6 py-4 bg-white md-max:w-full'">
        <div class="flex flex-wrap items-center">
          <div class="flex items-center w-2/3 pl-2 md-max:w-full">
            <div
              class="items-center justify-center mr-4 cursor-pointer back-button"
              @click="goBack"
            >
              <i class="fas fa-arrow-left"></i>
            </div>
            <span class="title">{{ titleText }}</span>
            <h3 class="pl-1 font-semibold text-gray-900 text-22px">
              Details (#{{ restricted_area.id | onlyLastFive }})
            </h3>
          </div>
          <div
            class="flex items-center justify-end w-1/3 pl-2 md-max:w-full md-max:pt-3"
          >
            <oto-edit-button @click="edit" />
          </div>
        </div>
        <!-- <div class="flex flex-wrap items-center my-12">
          <card-tax-stat
            title="Capacity"
            :value="restricted_area.max_vehicle"
            variant="orange"
            class="mr-3 md-max:mb-3"
          />
          <card-tax-stat
            title="Parked Vehicle"
            :value="parking_area.total_parked_vehicles"
            variant="light-blue"
            class="mr-3 md-max:mb-3"
          />
          <card-tax-stat
            title="Spot Left"
            :value="parking_area.total_spot_left"
            variant="red"
            class="mr-3 md-max:mb-3"
          />
        </div> -->
        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Restricted Area Name</span>
            <span class="block mt-4 detail-value">{{
              restricted_area.name
            }}</span>
          </div>
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Assigned Service Area</span>
            <span class="block mt-4 detail-value">{{
              restricted_area.geofence ? restricted_area.geofence.name : '--'
            }}</span>
          </div>
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Type</span>
            <span class="block mt-4 detail-value">{{
              restricted_area.draw_type == 'P' ? 'Polygon' : 'Circle'
            }}</span>
          </div>
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Address</span>
            <span class="block mt-4 detail-value">{{
              restricted_area.address ? restricted_area.address : ''
            }}</span>
          </div>
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">Note</span>
            <span class="block mt-4 detail-value">{{
              restricted_area.description ? restricted_area.description : ''
            }}</span>
          </div>
        </div>
        <input-label
          class="my-2 ml-2 font-bold"
          text="When Vehicle Goes Inside Of Restricted Area Should"
        />
        <div class="flex items-center md-max:flex-wrap">
          <div class="w-1/2 pl-2 pr-4 md-max:w-full md-max:px-0 md-max:py-3">
            <div
              :class="
                this.restricted_area.should_throttle == true
                  ? 'toggle-button-active flex items-center justify-center'
                  : 'toggle-button flex items-center justify-center'
              "
            >
              <div class="grid w-3/5">
                <span class="toggle-title">Throttle</span>
              </div>
              <div class="w-2/5">
                <div class="flex items-center">
                  <t-toggle
                    variant="success"
                    :checked="this.restricted_area.should_throttle"
                    readonly
                  />
                  <span class="ml-2 text-sm font-semibold">{{
                    this.restricted_area.should_throttle ? 'ON' : 'OFF'
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="w-1/2 pl-4 md-max:w-full md-max:pl-0">
            <div
              :class="
                this.restricted_area.should_trigger == true
                  ? 'toggle-button-active flex items-center justify-center'
                  : 'toggle-button flex items-center justify-center'
              "
            >
              <div class="grid w-3/5">
                <span class="toggle-title">Trigger Alarm</span>
              </div>
              <div class="w-2/5">
                <div class="flex items-center">
                  <t-toggle
                    variant="success"
                    :checked="this.restricted_area.should_trigger"
                    readonly
                  />
                  <span class="ml-2 text-sm font-semibold">{{
                    this.restricted_area.should_trigger ? 'ON' : 'OFF'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="
          is_full
            ? 'w-full relative'
            : 'w-1/2 relative md-max:w-full md-max:px-0'
        "
      >
        <!-- <right-alert
          :avatar="getScooterImage"
          :vehicle="alertVehicle"
          :item_class="
            `flex h-10 mt-1 mb-1 border-t-2 border-gray-200 items-center`
          "
          v-if="infoAlertOpen"
        /> -->

        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
          @click="toggleMap"
          @zoom_changed="getCurrentZoom"
        >
          <gmap-marker
            key="current"
            :position="getLatLng(restricted_area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/R_icon.png`),
              scaledSize: { width: 55, height: 65 },
            }"
            @mouseover="toggleMarker(restricted_area, -1)"
            @click="moveCamera(restricted_area)"
          >
          </gmap-marker>
          <gmap-marker
            :key="index"
            v-for="(area, index) in restricted_area.other_areas_in_geofence"
            :position="getLatLng(area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/R_icon.png`),
              scaledSize: { width: 55, height: 65 },
            }"
            @mouseover="toggleMarker(area, index)"
            @click="moveCamera(area)"
          >
          </gmap-marker>
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <div v-html="infoContent"></div>
          </gmap-info-window>
        </gmap-map>

        <div class="area-info">
          <!-- <div
            class="items-center justify-center full-map-button hidden-class"
            @click="fullMap"
          >
            {{ !is_full ? 'Full Map View' : 'Original Map View' }}
          </div> -->
          <div
            class="flex flex-wrap items-center justify-between px-4 area-body"
          >
            <round-tab
              v-for="(item, index) in bottom_tabs"
              :key="index"
              :type="item.type"
              :name="item.name"
              v-on:changeArea="changeArea"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RestrictedAreaConfig } from '@/config/RestrictedAreaConfig'
import { VehicleConfig } from '@/config/VehicleConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import { gmapApi } from 'vue2-google-maps'
import OtoEditButton from '@/components/ui/OtoEditButton.vue'
import AddEditRestrictedArea from '@/views/geofence/restricted-area/AddEditRestrictedArea'
import { EventBus } from '@/utils/EventBus'
// import RightAlert from '@/components/live-map/RightAlert'

// import CardTaxStat from "@/components/cards/TaxStat";
import RoundTab from '@/components/service-area/RoundTab.vue'

export default {
  name: 'ViewRestrictedArea',
  components: {
    OtoEditButton,
    AddEditRestrictedArea,
    // RightAlert,
    // CardTaxStat,
    RoundTab,
    InputLabel: () => import('@/components/form/InputLabel'),
  },
  data() {
    return {
      isLoading: false,
      serviceAreas: [],
      item: {},
      form: {
        should_trigger: true,
        should_throttle: false,
        coords: null,
      },
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      scrollwheel: true,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      drawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      restricted_area: null,
      is_full: false,
      vehicles: [],
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      alertVehicle: null,
      infoWinOpen: false,
      infoAlertOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -70,
        },
      },
      type: '',
      polygons: [],
      bottom_tabs: [
        {
          type: 'service',
          name: 'Service Area',
        },
        {
          type: 'restricted',
          name: 'Restricted Area',
        },
      ],
    }
  },
  watch: {},
  async created() {
    this.serviceAreas = await this.$http
      .get(DropdownConfig.api.serviceAreas)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    console.log('service = ', this.serviceAreas)
  },
  computed: {
    google: gmapApi,
    drawingType: function() {
      return [
        {
          id: 'CIRCLE',
          name: 'Circle',
        },
        {
          id: 'POLYGON',
          name: 'Polygon',
        },
      ]
    },
    id() {
      return this.$route.params.id
    },
    // getScooterImage() {
    //   return require('@/assets/img/scooter.png')
    // },
    titleText: function() {
      return 'Restricted Area /'
    },
  },
  async mounted() {
    this.initFunc()
    window.addEventListener(RestrictedAreaConfig.events.refresh, () => {
      this.initFunc()
    })
  },
  methods: {
    async initFunc() {
      this.isLoading = true
      await this.$http
        .get(RestrictedAreaConfig.api.details(this.id))
        .then(async (res) => {
          console.log('res = ', res)
          this.restricted_area = res.data
          this.isLoading = false
          this.vehicles = await this.$http
            .get(
              VehicleConfig.api.index +
                `?geofence=${this.restricted_area.geofence.id}`
            )
            .then((res) => res.data.data)
            .catch((err) => console.log(err))
          this.initDraw()
        })
        .catch((err) => {
          console.log('vehicle-header-err', err)
          this.isLoading = false
        })
      console.log('updated')
      // Code that will run only after the
      // entire view has been re-rendered
    },
    initDraw() {
      var self = this
      setTimeout(() => {
        if (!self.$refs.map) return
        console.log('getMIN')
        self.$refs.map.$mapPromise.then((map) => {
          console.log('Hitting promise with', map)
          self.map = map
          if (self.restricted_area != null) {
            console.log('park = ', self.restricted_area)
            if (self.restricted_area.geofence) {
              const overlay = self.parseToOverlay(
                self.restricted_area.geofence.coords
              )
              self.setServiceOverlayOnMap(overlay)
            }
            console.log(
              'GOT POLYGON COORDS FROM SERVER',
              self.restricted_area.coords
            )
            const overlay = self.parseToOverlay(self.restricted_area.coords)
            self.setOverlayOnMap(overlay)
            self.setMapCenterToDrawingCenter(overlay, false)
            this.drawOtherZonesOnMap()
          }
        })
      }, 1000)
    },
    drawOtherZonesOnMap() {
      const otherAreaBounds = []
      const otherParsedPolygons = []

      this.restricted_area.other_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.drawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.otherAreaBounds = otherAreaBounds
      this.otherParsedPolygons = otherParsedPolygons
    },
    fullMap() {
      this.is_full = !this.is_full
    },
    edit() {
      this.$http
        .get(RestrictedAreaConfig.api.details(this.id))
        .then((res) => {
          console.log('parking = ', res.data)
          delete res.data.created_by
          EventBus.$emit(RestrictedAreaConfig.events.editingData, res.data)
          dispatchEvent(new Event(RestrictedAreaConfig.events.sorToggle))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getCurrentZoom(z) {
      this.zoom = z
    },
    // initDrawingMode() {
    //   if (this.drawingManager) {
    //     this.drawingManager.setMap(null)
    //   }
    //   this.drawingManager = new this.google.maps.drawing.DrawingManager({
    //     drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
    //     drawingControl: true,
    //     drawingControlOptions: {
    //       position: this.google.maps.ControlPosition.TOP_CENTER,
    //       drawingModes: [
    //         this.google.maps.drawing.OverlayType.POLYGON,
    //         this.google.maps.drawing.OverlayType.CIRCLE,
    //       ],
    //     },
    //     // markerOptions: {
    //     //   icon:
    //     //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
    //     // },
    //     circleOptions: this.drawingOptions,
    //     polygonOptions: this.drawingOptions,
    //   })
    //   console.log('Manager ', this.drawingManager)
    //   this.drawingManager.setMap(this.map)
    //   this.google.maps.event.addListener(
    //     this.drawingManager,
    //     'overlaycomplete',
    //     this.overlayCompleted
    //   )
    //   // this.google.maps.event.addListener(
    //   //   this.drawingManager,
    //   //   "polygoncomplete",
    //   //   function(polygon) {
    //   //   }
    //   // );
    //   this.google.maps.event.addListener(
    //     this.drawingManager,
    //     'circlecomplete',
    //     this.circleCompleted
    //   )
    // },
    setServiceOverlayOnMap(payload) {
      this.parsedServicePolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.serviceDrawingOptions,
      })
      this.parsedServicePolygon.setMap(this.map)
      console.log('po = ', this.parsedServicePolygon)
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    setOverlayOnMap(payload) {
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })
      this.parsedPolygon.setMap(this.map)
      console.log(payload)
      console.log('SET ON MAP')
    },
    setMapCenterToDrawingCenter(paths, panToCenter = true) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter)
      if (panToCenter) {
        this.map.panTo(newCenter)
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }
      //reset map zoom based on new poly (can't see whole poly on render if the poly is bigger then the current set zoom)
      this.map.fitBounds(bounds)
      // if (!this.parsedPolygon) return
      // const polyBounds = this.getPolygonBounds(this.parsedPolygon)
      // const newZoomLevel = this.getZoomLevelByBounds(this.map, polyBounds)
      // this.map.setZoom(newZoomLevel)
    },

    goBack() {
      this.$router.push('/geofence/restricted-areas')
    },
    changeArea(type) {
      this.parsedServicePolygon.setMap(null)
      this.parsedPolygon.setMap(null)
      if (type == this.type) {
        this.type = ''
        this.initDraw()
        return
      } else {
        this.type = type
      }

      if (!this.$refs.map) return

      this.$refs.map.$mapPromise.then((map) => {
        this.map = map
        if (type == 'service') {
          if (this.restricted_area.geofence) {
            const overlay = this.parseToOverlay(
              this.restricted_area.geofence.coords
            )
            this.setServiceOverlayOnMap(overlay)
          }
        } else {
          if (this.restricted_area != null) {
            const overlay = this.parseToOverlay(this.restricted_area.coords)
            this.setOverlayOnMap(overlay)
            this.setMapCenterToDrawingCenter(overlay, false)
          }
        }
      })
    },

    // getIconMap(vehicle) {
    //   if (vehicle.status_flags) {
    //     let data = vehicle.status_flags
    //     if (data.is_charging) {
    //       return 'is_charging'
    //     } else if (data.is_on_ride) {
    //       return 'is_on_ride'
    //     } else if (data.is_parking) {
    //       return 'is_parking'
    //     } else if (data.is_reserved) {
    //       return 'is_reserved'
    //     }
    //   }
    //   if (vehicle.general_flags) {
    //     let data = vehicle.general_flags
    //     if (data.geo_fence_alert) {
    //       return 'geo_fence_alert'
    //     } else if (data.iot_fault) {
    //       return 'iot_fault'
    //     } else if (data.missing) {
    //       return 'missing'
    //     } else if (data.low_battery) {
    //       return 'low_battery'
    //     }
    //   }
    //   if (vehicle.operational_flags) {
    //     let data = vehicle.operational_flags
    //     if (data.charging_pick) {
    //       return 'charging_pick'
    //     } else if (data.maintainance) {
    //       return 'maintainance'
    //     } else if (data.rebalance) {
    //       return 'rebalance'
    //     }
    //   }
    //   if (vehicle.lock) {
    //     let data = vehicle.lock
    //     if (Number(data.power_level) >= 74) {
    //       return 'full'
    //     } else if (
    //       Number(data.power_level) >= 50 &&
    //       Number(data.power_level) < 74
    //     ) {
    //       return 'normal'
    //     } else if (
    //       Number(data.power_level) >= 25 &&
    //       Number(data.power_level) < 50
    //     ) {
    //       return 'medium'
    //     } else if (
    //       Number(data.power_level) >= 5 &&
    //       Number(data.power_level) < 25
    //     ) {
    //       return 'low'
    //     } else if (
    //       Number(data.power_level) > 0 &&
    //       Number(data.power_level) < 5
    //     ) {
    //       return 'critical'
    //     } else if (Number(data.power_level) == 0) {
    //       return 'empty'
    //     }
    //   }
    //   return 'full'
    // },

    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = Number(position.split(',')[0])
      let lng = Number(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },

    toggleMap() {
      this.infoWinOpen = false
      // this.infoAlertOpen = false
    },
    moveCamera(area) {
      this.infoWinOpen = false
      // this.infoAlertOpen = true
      // this.alertVehicle = vehicle

      if (area.center_coords != null) {
        this.center = this.getLatLng(area.center_coords)
        this.zoom = 15
      }
    },
    // toggleAlert(vehicle) {
    //   this.infoWinOpen = false
    //   this.infoAlertOpen = true
    //   this.alertVehicle = vehicle

    //   if (vehicle.location != null) {
    //     this.center = this.getLatLng(vehicle.location)
    //     this.zoom = 15
    //   }
    // },

    toggleMarker(area, idx) {
      this.infoWindowPos = this.getLatLng(area.center_coords)
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
      this.infoContent = this.getInfoWindowContent(area)
    },

    // showAlert(item) {
    //   console.log('alert')
    //   if (item.location != null) {
    //     this.center = this.getLatLng(item.location)
    //     this.zoom = 15
    //   } else {
    //     this.zoom = 2
    //   }
    //   this.infoWinOpen = false
    //   this.infoAlertOpen = true
    //   this.alertVehicle = item
    // },

    getInfoWindowContent: function(item) {
      //work from here

      return `
        <div class="card">
          <div class="card-content">
            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Parking Area Name:</span>
              <span class="ml-2 card-text ">
              <a href="/geofence/restricted-areas/${
                item.id
              }" class="font-semibold text-blue-600 capitalize " target="_blank"> ${
        item.name == undefined ? ' ' : item.name
      } </a>
              </span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Address:</span>
              <span class="ml-2 card-text">${item.address}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Latitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lat
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Longtitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lng
              }</span>
            </div>          

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Active Status:</span>
              <span class="ml-2 card-text font-semibold">${
                item.is_active
                  ? '<span class="text-green-600">Yes<span>'
                  : '<span class="text-gray-600">No<span>'
              }</span>
            </div>            
          </div>
        </div>
      `
    },

    // getNormalIcon(vehicle) {
    //   if (vehicle.status_flags) {
    //     let data = vehicle.status_flags
    //     if (data.is_charging) {
    //       return false
    //     } else if (data.is_on_ride) {
    //       return false
    //     } else if (data.is_parking) {
    //       return false
    //     } else if (data.is_reserved) {
    //       return false
    //     }
    //   }
    //   if (vehicle.general_flags) {
    //     let data = vehicle.general_flags
    //     if (data.geo_fence_alert) {
    //       return false
    //     } else if (data.iot_fault) {
    //       return false
    //     } else if (data.missing) {
    //       return false
    //     } else if (data.low_battery) {
    //       return false
    //     }
    //   }
    //   if (vehicle.operational_flags) {
    //     let data = vehicle.operational_flags
    //     if (data.charging_pick) {
    //       return false
    //     } else if (data.maintainance) {
    //       return false
    //     } else if (data.rebalance) {
    //       return false
    //     }
    //   }
    //   return true
    // },
  },
}
</script>

<style lang="scss" scoped>
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; // 15px 0?

  & .left {
    width: 65%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  & .right {
    width: 35%;
    padding-left: 0.5rem;
  }
}
.toggle-button-active {
  border: 1px solid #1bca09;
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-button {
  width: 100%;
  height: 140px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.toggle-description {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  @apply text-gray-600;
}
.detail-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.detail-value {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #787881;
}
.title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9393a2;
}
.area-info {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 40px;
  display: flex;
  .area-body {
    width: 270px;
    height: 100%;
    margin-left: 20px;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 0px #757575bf;
    .service-area-circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: #dff7e4;
      border: 1px solid #00b44d;
      margin-right: 5px;
    }
    .parking-area-circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: #dff7e4;
      border: 1px solid #00acff;
      margin-right: 5px;
    }
    .restricted-area-circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: #ffd7bb;
      border: 1px solid #ff7c10;
      margin-right: 5px;
    }
    .slow-area-circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: #ffe4e4;
      border: 1px solid #ff0000;
      margin-right: 5px;
    }
    .bottom-area-text {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2e2f40;
    }
  }
  .full-map-button {
    width: 150px;
    background: white;
    height: 40px;
    margin-left: 30px;
    border-radius: 5px;
    display: flex;
    box-shadow: 1px 1px 6px 0px #757575bf;
    cursor: pointer;

    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #21212a;
  }
}
.active_service {
  color: #00b44d !important;
}
.active_parking {
  color: #00acff !important;
}
.active_restricted {
  color: #ff7c10 !important;
}
.active_slow_zone {
  color: #ff0000 !important;
}
@media (max-width: 990px) {
  .hidden-class {
    display: none !important;
  }
}
</style>
